import React from "react"
import SEO from "../../components/seo"
import Card from "../../components/card"
// import Button from "../../components/button"
import Footer from "../../components/footer"
import Layout from "../../components/layout"
// import { vP } from "../../components/gutters"
import presets, { colors } from "../../utils/presets"
import typography, { rhythm, scale, options } from "../../utils/typography"
import { Link } from "gatsby";
import { itemListCase } from "../../utils/sidebar/item-list"

const CasePage = ({ location }) => (
    <Layout location={location} itemList={itemListCase} contentTitle="解决方案">
        <SEO title="解决方案" />
        {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
            <h2 css={styles.contentTitle}>
                <span>
                    解决方案
                </span>
            </h2>
        </div> */}
        <div css={styles.contact} style={{minHeight: 'calc(100vh - 383px)'}}>
            <div css={styles.cards}>
                {itemListCase.map((card, i) => {
                  if (card.link !== '/case/') {
                    return (
                      <Card key={i} location={location}>
                          <h2 css={styles.cardHeadLine}>{card.title}</h2>
                          {card.items && card.items.length && card.items.map((item, j) => {
                            return (<div key={j} css={styles.futuraParagraph}><Link to={item.link}>{item.title}</Link></div>)
                          })}
                      </Card>
                    )
                  }
                  return null
                })}
            </div>
        </div>
        {/* <div css={styles.footer}>
          <Footer />
        </div> */}
    </Layout>
)

const styles = {
    cards: {
        borderRadius: presets.radiusLg,
        display: `flex`,
        flexWrap: `wrap`,
        width: `100%`,
    },
    cardHeadLine: {
        ...scale(2 / 5),
        lineHeight: 1.2,
        marginTop: 0,
        fontSize: 18
        // [presets.Tablet]: {
        //     fontSize: scale(1 / 10).fontSize,
        // },
        // [presets.Desktop]: {
        //     fontSize: scale(3 / 10).fontSize,
        // },
        // [presets.VHd]: {
        //     fontSize: scale(5 / 10).fontSize,
        // },
        // [presets.VVHd]: {
        //     fontSize: scale(7 / 10).fontSize,
        // },
    },
    contact: {
        flex: `0 0 100%`,
        maxWidth: `100%`,
        padding: rhythm(presets.gutters.default / 2),
        [presets.Hd]: { padding: 24 },
    },
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    futuraParagraph: {
        fontFamily: options.headerFontFamily.join(`,`),
        fontSize: 14,
        marginBottom: 10,
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
}

export default CasePage
